import React from 'react';
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";

import TheHeader from '../final-page/TheHeader';
import AboutMe from '../final-page/AboutMe';
import Portfolio from '../final-page/Portfolio';
import ContactMe from '../final-page/ContactMe';
import Triptico from '../final-page/Triptico';
// import BackToTop from '../final-page/BackToTop';
import MainSlider from '../final-page/MainSlider';


const FinalPage = () => {
  return (
    <div>
      <Helmet pageTitle="Luis Dona"/>
      <TheHeader/>

      <MainSlider/>

      <Portfolio/>

      <AboutMe/>

      <ContactMe/>

      <Triptico/>

      <FooterTwo />
      {/* <BackToTop/> */}
    </div>
  )
}

export default FinalPage;
