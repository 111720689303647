import React, { Component } from "react";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { FiX, FiMenu} from "react-icons/fi";
// import Scrollspy from 'react-scrollspy'
import { Link } from 'react-scroll' // replaces react-scrollspy

const SocialShare = [
  {Social: <FaFacebookF /> , link: 'https://www.facebook.com/profile.php?id=100054349939727'},
  {Social: <FaInstagram /> , link: 'https://www.instagram.com/donaartegrafico/'},
  // {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
  // {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

const navbarItems = [
  { anchor: 'home', title: 'Inicio' },
  { anchor: 'portfolio', title: 'Portfolio' },
  { anchor: 'about', title: 'Sobre mi' },
]

class HeaderThree extends Component{
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener('load', function() {
      console.log('All assets are loaded');
    })
  }
  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }
  CLoseMenuTrigger() {
    document.querySelector('.header-wrapper').classList.remove('menu-open')
  }
  stickyHeader () {}
  render(){
    window.addEventListener('scroll', function() {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky')
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky')
      }
    });
    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function() {
          this.parentElement.querySelector('.submenu').classList.toggle("active");
          this.classList.toggle("open");
        }
      }
    }
    return(
      <header className={`header-area header-style-two header--fixed color-black`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href="/">
                <img src="/assets/images/logo/sign.png" alt="Luis Dona" />
              </a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <ul className="mainmenu">
                {navbarItems.map((navItem , index) => (
                  <li>
                    <Link
                    activeClass="is-current"
                    className="mainmenu"
                    to={navItem.anchor}
                    spy={true}
                    smooth={true}
                    duration={1500}
                    >
                      {navItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
              {/* <Scrollspy
              className="mainmenu"
              items={['home','about','portfolio','contact']}
              currentClassName="is-current"
              offset={-200}
              >
                <li><a href="#home">Inicio</a></li>
                <li><a href="#about">Sobre mi</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#contact">Contacto</a></li>
              </Scrollspy> */}
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner d-none d-sm-block">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {SocialShare.map((val , i) => (
                  <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                ))}
              </ul>
            </div>
            {/* <div className="header-btn">
              <a className="btn-default btn-border btn-opacity" target="_blank" href="https://themeforest.net/checkout/from_item/31405042?license=regular">
                <span>buy Now</span>
              </a>
            </div> */}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
              <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
export default HeaderThree;