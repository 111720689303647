import React from 'react';
import '../../public/assets/scss/custom/triptico.scss';

const imgUrl = 'assets/images/works/obras/personajes-fantasticos/la-ultima-penia/la-ultima-penia-borderless.jpg';

export default () => {
  return (
    <div className="triptico-container" title="La última peña: tríptico">
      <div className="triptico--img">
        {/* <div className="triptico--title">
          La última peña (tríptico)
        </div> */}
        <img src={`${imgUrl}`} alt="Tríptico"/>
      </div>
    </div>
  );
}