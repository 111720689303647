import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CkSwitchOptions from './components/ck-switch-options';
// import Pagination from "../elements/common/Pagination";
import WorkDetails from '../final-page/WorkDetails';
import '../../public/assets/scss/custom/portfolio.scss';
// hooks
import { globalHooks } from '../hooks/global-hooks';

// set data
import allWorks from './works';
import allWorkCollections from './works-collections';
import allWorkCategories from './works-categories';
import allWorkMediums from './works-mediums';


allWorks.forEach(work => {
  const collection = allWorkCollections.find(coll => coll.id === work.collectionId);
  const category = allWorkCategories.find(cat => cat.id === collection.categoryId);
  const medium = allWorkMediums.find(med => med.id === work.mediumId);
  work.collectionName = collection.name;
  work.collectionFolder = collection.folderName;
  work.categoryFolder = category.folderName;
  work.medium = medium ? medium.name : '';
  work.imgUrl = globalHooks.getWorkImgPath(work, { smallVersion: true });
  console.log('work.imgUrl', work.imgUrl);
});

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredCollections: [...allWorkCollections.filter(c => c.categoryId === 1)],
      filteredWorks: [...allWorks.filter(w => w.collectionId === 1)],
      isDetailsActive: false,
      selectedWork: {
        title: '',
        year: 0,
        sizeX: 0,
        sizeY: 0,
        medium: '',
        imgUrl: '',
      }
    };
  }
  filterWorks = (collectionId) => {
    let list = [...allWorks];
    if (collectionId) {
      list = allWorks.filter(w => w.collectionId === collectionId);
    }
    this.setState({ filteredWorks: list });
  }
  filterCollections = (categoryId) => {
    console.log('categoryId', categoryId);
    const list = allWorkCollections.filter(c => c.categoryId === categoryId);
    console.log('list', list);
    this.setState({ filteredCollections: [] }); // force child update
    this.setState({ filteredCollections: list });
    this.filterWorks(list[0].id);
  }
  render() {
    const { filteredWorks, filteredCollections, isDetailsActive, selectedWork } = this.state;
    // const collectionsList = [
    //   { id: 0, name: 'Todos' },
    //   { id: 1, name: 'Personajes Fantásticos' },
    //   { id: 2, name: 'Báculos' },
    // ];
    const handleClose = () => {
      this.setState({ isDetailsActive: false });
      console.log('cierro');
    }
    // // let isDetailsActive = false;
    const openDetails = (work) => {
      this.setState({ isDetailsActive: true });
      this.setState({ selectedWork: work });
    //   // isDetailsActive = true;
    //   setIsOpen(true);
    }
    return (
      <div id="portfolio" className="fix">
        <WorkDetails
        title={selectedWork.title}
        subtitle={selectedWork.subtitle}
        year={selectedWork.year}
        sizeX={selectedWork.sizeX}
        sizeY={selectedWork.sizeY}
        medium={selectedWork.medium}
        imgUrl={selectedWork.imgUrl}
        handleClose={handleClose}
        isOpen={isDetailsActive}
        />
        <div className="portfolio-area pb--120 bg_color--5 pt--60">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30 mb_sm--0">
                    <h2 className="title">Mis trabajos</h2>
                  </div>
                </div>
              </div>
              {/* options */}
              <div id={this.filterCollections.length} style={{ display: 'flex' }}>
                <CkSwitchOptions
                options={allWorkCategories}
                onClick={this.filterCollections}
                />
              </div>
              {/* options */}
              {filteredCollections.length > 1 && (
                <div style={{ display: 'flex' }}>
                  <CkSwitchOptions
                  prop="title"
                  options={filteredCollections}
                  onClick={this.filterWorks}
                  />
                </div>
              )}
              {/* list */}
              <div className="row portfolio__works-container">
                <React.Fragment> 
                  {filteredWorks.map((work , index) => (
                    <div className={`col-lg-4 col-md-6 col-sm-6 col-12`} key={index}>
                      <div className={`im_portfolio text-center mt--40`}
                      onClick={() => { openDetails(work) }}
                      >
                        <div className="thumbnail_inner">
                          <div className="thumbnail">
                            <Link to="#">
                            {/* <Link to="/portfolio-details"> */}
                            <img src={`${work.imgUrl}`} alt="Portfolio Imagen"/>
                            </Link>
                          </div>
                        </div>
                        <div className="content">
                          <div className="inner">
                            <div className="portfolio_heading">
                              <div className="category_list">
                                <Link to="#">
                                {/* <Link to="/portfolio-details"> */}
                                  {work.collectionName}
                                </Link>
                              </div>
                              <h4 className="title">
                                <Link to="#">
                                {/* <Link to="/portfolio-details"> */}
                                  {work.title}
                                </Link>
                              </h4>
                            </div>
                            <div className="portfolio_hover">
                              <p>{work.description}</p>
                            </div>
                          </div>
                        </div>
                        {/* <Link className="transparent_link" to="/portfolio-details"></Link> */}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 mt_sm--30 text-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      // <Pagination />

    );
  }
}

export default Portfolio;
