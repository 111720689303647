export default [
  {
    id: 1,
    folderName: 'obras',
    name: 'Obras',
  },
  {
    id: 2,
    folderName: 'restauraciones',
    name: 'Restauración',
  },
  {
    id: 3,
    folderName: 'arte-3d',
    name: 'Arte 3D',
  },
]