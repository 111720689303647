import React from 'react';
// import ContactForm from "./components/contactMe/ContactForm";
import '../../public/assets/scss/custom/contact-me.scss';

const ContactMe = () => {
  // const email = 'hola@luisdona.com';
  const email = 'dona_artegrafico@yahoo.com.ar';
  const phone = '+54 9 3404 50-6452';
  return (
    <div id="contact" className="fix">
      {/* <div className="rn-contact-area pb--120 bg_color--5">
        <div className="contact-form--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 order-1 order-lg-2 contact-me__img-container">
                <div className="thumbnail mb_md--30 mb_sm--30">
                  <img src="/assets/images/about/about-11.jpg" alt="trydo"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ContactMe;
