export default [
  {
    id: 1,
    categoryId: 1,
    folderName: 'personajes-fantasticos',
    title: 'Personajes Fantásticos',
  },
  {
    id: 2,
    categoryId: 1,
    folderName: 'baculos',
    title: 'Elementos mágicos',
  },
  {
    id: 3,
    categoryId: 2,
    folderName: 'parroquia-san-carlos-borromeo',
    title: 'Parroquia San Carlos Borromeo',
  },
  {
    id: 4,
    categoryId: 2,
    folderName: 'templo-san-cayetano',
    title: 'Templo San Cayetano',
  },
  {
    id: 5,
    categoryId: 3,
    folderName: 'arte-vereda',
    title: 'Arte vereda',
  },
  {
    id: 6,
    categoryId: 1,
    folderName: 'naturaleza-muerta',
    title: 'Naturaleza Muerta',
  },
  {
    id: 7,
    categoryId: 1,
    folderName: 'retratos',
    title: 'Retratos',
  },
  {
    id: 8,
    categoryId: 1,
    folderName: 'the-beatles',
    title: 'The Beatles',
  },
];
