export const globalHooks = {
  getWorkImgPath(work, { smallVersion = false }) {
    // fileName
    let fileName = work.fileName;
    if (!fileName) fileName =  work.folderName;
    if (!work.justOneImg) fileName = `${fileName}-1`;
    const showSmall = smallVersion && work.hasSmallVersion;
    if (showSmall) fileName = `${fileName}-small`;
    // folderName
    let folderName = `${work.categoryFolder}/${work.collectionFolder}`
    if (work.folderName) folderName = `/${folderName}${work.fileName || ''}`;
    if (!work.fileName) folderName = `/${folderName}/${work.folderName}`;
    // folderAndFileName
    let folderAndFileName = fileName;
    if (folderName) folderAndFileName = `${folderName}/${folderAndFileName}`;
    // extension
    let extension = 'jpg';
    if (work.extension) extension = work.extension;
    if (showSmall && work.extensionSmall) extension = work.extensionSmall;
    // save result
    return `/assets/images/works/${folderAndFileName}.${extension}`;
  },
};
