import React from 'react';
import '../../public/assets/scss/custom/work-details.scss';
// hooks
import { globalHooks } from '../hooks/global-hooks';

const WorkDetails = ({ title, subtitle, year, sizeX, sizeY, medium, imgUrl, handleClose, isOpen }) => {
  const onClickBackground = (event) => {
    const classList = [...event.target.classList];
    const checkIsBg = (classList) => {
      if (classList.some((i) => i === 'work-details__background')) return true;
      if (classList.some((i) => i === 'work-details__content-container')) return true;
      if (classList.some((i) => i === 'work-details__main-container')) return true;
      return false;
    }
    if (checkIsBg(classList)) {
      handleClose();
    }
  }
  if (isOpen) {
    return (
      <div className="work-details__background" onClick={onClickBackground}>
        <div className="work-details__main-container">
          <div className="work-details__content-container">
            <div className="work-details__img-container">
              <img src={`${imgUrl}`} alt="Obra"/>
            </div>
            <div className="work-details__data-container">
              <div className="work-details__data-header">
                <div className="work-title">
                  <span className="work-title--title">{ title }</span>
                  <span className="work-title--subtitle">{ subtitle }</span>
                </div>
                <div className="work-details__close-btn" onClick={handleClose}>X</div>
              </div>
              <div className="filed-container">
                {
                year && <div>
                  <span className="field">Año:</span>
                  <span className="field-value">{ year }</span>
                </div>
                }
                {(sizeX && sizeY) && <div>
                  <span className="field">Medidas:</span>
                  <span className="field-value">{ sizeX }x{sizeY}cm</span>
                </div>}
                <div>
                  <span className="field">Técnica:</span>
                  <span className="field-value">{ medium }</span>
                <div>
                  <span className="field">Artista:</span>
                  <span className="field-value">Luis Dona</span>
                </div>
                </div>
              </div>
              <div className="sign-container">
                <img src="/assets/images/logo/sign.png" alt="Luis Dona" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (null)
  }
}

export default WorkDetails;
