import React, { useState, useEffect } from 'react';

import './ck-switch-options.style.css';

const CkSwitchOptions = ({ options, prop, onClick }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    if (options.length) {
      setSelectedOption(options[0].id);
    }
    if (!prop);
  }, [options, prop]);
  const handleChildClick = (option) => {
    setSelectedOption(option.id);
    if (onClick) onClick(option.id);
  }
  return (
    <ul key={options.length} className='ck-switch-options'>
      {options.map((option, index) => (
        <li
        key={index}
        className={`ck-switch-options__item ${selectedOption === option.id ? 'is-selected' : ''}`}
        onClick={() => { handleChildClick(option); }}
        >
          { option[prop || 'name'] }
        </li>
      ))}
    </ul>
  );
}

export default CkSwitchOptions;
