export default [
  {
    id: 1,
    name: 'Lápiz sobre madera',
  },
  {
    id: 2,
    name: 'Tiza sobre cemento',
  },
];
