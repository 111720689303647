export default [
  {
    id: 1,
    collectionId: 1,
    folderName: 'eneles',
    title: 'Enelés',
    subtitle: 'El ideólogo',
    imagesQuantity: 3,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 2,
    collectionId: 1,
    folderName: 'epul',
    title: 'Epul',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 3,
    collectionId: 1,
    folderName: 'euquirne',
    title: 'Euquirne',
    subtitle: 'El jóven',
    imagesQuantity: 2,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 4,
    collectionId: 1,
    folderName: 'euquirne-ii',
    title: 'Euquirne II',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 5,
    collectionId: 1,
    folderName: 'hanyretak',
    title: 'Hanyretak',
    subtitle: 'De Solonia',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 6,
    collectionId: 1,
    folderName: 'la-ultima-penia',
    title: 'La última peña',
    subtitle: 'Tríptico',
    imagesQuantity: 4,
    year: 2020,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 7,
    collectionId: 1,
    folderName: 'licha',
    title: 'Allad Ornasil',
    subtitle: 'El Repoleved',
    imagesQuantity: 1,
    year: 2020,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 8,
    collectionId: 1,
    folderName: 'luar',
    title: 'Luar',
    subtitle: 'El vigilante',
    imagesQuantity: 2,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 9,
    collectionId: 1,
    folderName: 'mis-amigos',
    title: 'Mis amigos',
    subtitle: '',
    imagesQuantity: 4,
    year: 2020,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 10,
    collectionId: 1,
    folderName: 'nabetse-le',
    title: 'Nabetse-le',
    subtitle: '',
    imagesQuantity: 3,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 11,
    collectionId: 1,
    folderName: 'nitram',
    title: 'Nitram',
    subtitle: 'El viejo',
    imagesQuantity: 2,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 12,
    collectionId: 1,
    folderName: 'nomar',
    title: 'Nomar',
    subtitle: 'El Osteólogo',
    imagesQuantity: 3,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 13,
    collectionId: 1,
    folderName: 'nomis',
    title: 'Nomis',
    subtitle: 'El bur',
    imagesQuantity: 2,
    year: 2020,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 14,
    collectionId: 1,
    folderName: 'oflodor',
    title: 'Oflodor',
    subtitle: 'El Poderoso',
    imagesQuantity: 5,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 15,
    collectionId: 1,
    folderName: 'saitam',
    title: 'Saitam',
    subtitle: 'Saisi',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 16,
    collectionId: 1,
    folderName: 'tito',
    title: 'Tito',
    subtitle: '',
    imagesQuantity: 3,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 17,
    collectionId: 1,
    folderName: 'yoib',
    title: 'Yoib',
    subtitle: '',
    imagesQuantity: 2,
    year: 2019,
    sizeX: 118,
    sizeY: 83,
    mediumId: 1,
  },
  {
    id: 18,
    collectionId: 2,
    folderName: 'baculo-01',
    title: 'Báculo de Epul ',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 19,
    collectionId: 2,
    folderName: 'baculo-02.a',
    title: 'Báculo de Tito (I)',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 20,
    collectionId: 2,
    folderName: 'baculo-02.b',
    title: 'Báculo de Tito (II)',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 21,
    collectionId: 2,
    folderName: 'baculo-03',
    title: 'Báculo de Luar',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 22,
    collectionId: 2,
    folderName: 'baculo-04',
    title: 'Báculo de Nomis',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 23,
    collectionId: 2,
    folderName: 'baculo-05',
    title: 'Báculo de Euquirne el joven',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 24,
    collectionId: 2,
    folderName: 'baculo-06',
    title: 'Báculo de Euquirne II',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 25,
    collectionId: 2,
    folderName: 'baculo-07',
    title: 'Báculo de Yoib',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 26,
    collectionId: 2,
    folderName: 'baculo-08',
    title: 'Báculo de Nitram',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 27,
    collectionId: 2,
    folderName: 'baculo-09',
    title: 'Báculo de Saitam Saisi',
    subtitle: '',
    imagesQuantity: 1,
    year: 2019,
    sizeX: 111,
    sizeY: 222,
    mediumId: 1,
  },
  {
    id: 28,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-1',
  },
  {
    id: 29,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-2',
  },
  {
    id: 30,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-3',
  },
  {
    id: 31,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-4',
  },
  {
    id: 32,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-5',
  },
  {
    id: 33,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-6',
  },
  {
    id: 34,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-7',
  },
  {
    id: 35,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-8',
  },
  {
    id: 36,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-9',
  },
  {
    id: 37,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-10',
  },
  {
    id: 38,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-11',
  },
  {
    id: 39,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-12',
  },
  {
    id: 40,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-13',
  },
  {
    id: 41,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-14',
  },
  {
    id: 42,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-15',
  },
  {
    id: 43,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-16',
  },
  {
    id: 44,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-17',
  },
  {
    id: 45,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-18',
  },
  {
    id: 46,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-19',
  },
  {
    id: 47,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-20',
  },
  {
    id: 48,
    collectionId: 3,
    fileName: 'parroquia-san-carlos-borromeo-21',
  },
  {
    id: 49,
    collectionId: 4,
    fileName: 'templo-san-cayetano-1',
  },
  {
    id: 50,
    collectionId: 4,
    fileName: 'templo-san-cayetano-2',
  },
  {
    id: 51,
    collectionId: 4,
    fileName: 'templo-san-cayetano-3',
  },
  {
    id: 52,
    collectionId: 4,
    fileName: 'templo-san-cayetano-4',
  },
  {
    id: 53,
    collectionId: 4,
    fileName: 'templo-san-cayetano-5',
  },
  {
    id: 54,
    collectionId: 4,
    fileName: 'templo-san-cayetano-6',
  },
  {
    id: 55,
    collectionId: 4,
    fileName: 'templo-san-cayetano-7',
  },
  {
    id: 56,
    collectionId: 4,
    fileName: 'templo-san-cayetano-8',
  },
  {
    id: 57,
    collectionId: 4,
    fileName: 'templo-san-cayetano-9',
  },
  {
    id: 58,
    collectionId: 4,
    fileName: 'templo-san-cayetano-10',
  },
  {
    id: 59,
    collectionId: 4,
    fileName: 'templo-san-cayetano-11',
  },
  {
    id: 60,
    collectionId: 4,
    fileName: 'templo-san-cayetano-12',
  },
  {
    id: 61,
    collectionId: 4,
    fileName: 'templo-san-cayetano-13',
  },
  {
    id: 62,
    collectionId: 5,
    folderName: 'ser-nino-de-nuevo',
    title: 'Ser niño de nuevo',
    imagesQuantity: 4,
    mediumId: 2,
    hasSmallVersion: true,
  },
  {
    id: 63,
    collectionId: 5,
    folderName: 'sobrevolando-recuerdos',
    title: 'Sobrevolando recuerdos',
    imagesQuantity: 3,
    mediumId: 2,
    hasSmallVersion: true,
  },
  {
    id: 64,
    collectionId: 6,
    fileName: 'naturaleza-muerta-1',
    justOneImg: true,
    extension: 'jpeg',
  },
  {
    id: 65,
    collectionId: 6,
    fileName: 'naturaleza-muerta-2',
    justOneImg: true,
    extension: 'jpeg',
  },
  {
    id: 66,
    collectionId: 6,
    fileName: 'naturaleza-muerta-3',
    justOneImg: true,
    extension: 'jpeg',
  },
  {
    id: 67,
    collectionId: 6,
    fileName: 'naturaleza-muerta-4',
    justOneImg: true,
    extension: 'jpeg',
  },
  {
    id: 68,
    collectionId: 6,
    fileName: 'naturaleza-muerta-5',
    justOneImg: true,
    extension: 'jpeg',
  },
  {
    id: 69,
    collectionId: 6,
    fileName: 'naturaleza-muerta-6',
    justOneImg: true,
    extension: 'jpeg',
  },
  {
    id: 70,
    collectionId: 6,
    fileName: 'naturaleza-muerta-7',
    justOneImg: true,
    extension: 'jpeg',
  },
  {
    id: 71,
    collectionId: 6,
    fileName: 'naturaleza-muerta-8',
    justOneImg: true,
    extension: 'jpeg',
  },
  {
    id: 72,
    collectionId: 7,
    fileName: 'retrato-1',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 73,
    collectionId: 7,
    fileName: 'retrato-2',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 73,
    collectionId: 7,
    fileName: 'retrato-3',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 74,
    collectionId: 7,
    fileName: 'retrato-4',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 75,
    collectionId: 7,
    fileName: 'retrato-5',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 76,
    collectionId: 7,
    fileName: 'retrato-6',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 77,
    collectionId: 7,
    fileName: 'retrato-7',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 78,
    collectionId: 7,
    fileName: 'retrato-8',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 79,
    collectionId: 7,
    fileName: 'retrato-9',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 80,
    collectionId: 7,
    fileName: 'retrato-10',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 81,
    collectionId: 7,
    fileName: 'retrato-11',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 82,
    collectionId: 7,
    fileName: 'retrato-12',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
  },
  {
    id: 83,
    collectionId: 7,
    fileName: 'retrato-13',
    justOneImg: true,
    extension: 'jpeg',
    hasSmallVersion: true,
    // extensionSmall: 'jpg',
  },
  {
    id: 84,
    collectionId: 8,
    fileName: 'the-beatles-1',
    justOneImg: true,
    hasSmallVersion: true,
  },
  {
    id: 85,
    collectionId: 8,
    fileName: 'the-beatles-2',
    justOneImg: true,
    hasSmallVersion: true,
  },
  {
    id: 86,
    collectionId: 8,
    fileName: 'the-beatles-3',
    justOneImg: true,
    hasSmallVersion: true,
  },
  {
    id: 87,
    collectionId: 8,
    fileName: 'the-beatles-4',
    justOneImg: true,
    hasSmallVersion: true,
  },
  {
    id: 88,
    collectionId: 8,
    fileName: 'the-beatles-5',
    justOneImg: true,
    hasSmallVersion: true,
  },


  
  // {
  //   id: 0,
  //   collectionId: 0,
  //   folderName: '',
  //   title: '',
  //   subtitle: '',
  //   imagesQuantity: 0,
  // },
];
