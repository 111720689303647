import React, { Component } from 'react';
import TextLoop from "react-text-loop";
import ModalVideo from 'react-modal-video';

const SlideList = [
  {
    textPosition: 'text-left',
    category: 'Bienvenido a mi mundo',
    description: '',
    buttonText: '',
    buttonLink: ''
  }
]

class MainSlider extends Component {
  constructor() {
    super()
    this.state = {
        isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({isOpen: true})
  }
  render() {
    return (
      <div id="home" className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value , index) => (
          <div className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height" key={index}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="designer-thumbnail">
                    <img src="/assets/images/avatar/main-slider.png" alt="Slider Images"/>
                  </div>
                </div>
                <div className="col-lg-7 mt_md--40 mt_sm--40">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ''}
                    <h1 className="title">Hola, Soy Luis Dona<br/>
                    <TextLoop>
                      <span>Artista plástico.</span>
                      <span>Restaurador de<br/>pintura mural.</span>
                    </TextLoop>{" "}
                    </h1>
                    {value.description ? <p className="description">{value.description}</p> : ''}
                    {/* <div className="d-flex align-items-center mt--10">
                      <ModalVideo
                      channel='youtube'
                      isOpen={this.state.isOpen}
                      videoId='ZOoVOfieAF8'
                      onClose={() => this.setState({isOpen: false})}
                      />
                      <button className="video-popup theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>
    );
  }
}

export default MainSlider;
