import React from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCheck } from "react-icons/fi";
import '../../public/assets/scss/custom/about-me.scss';

const history = `
Nació en San Carlos Centro, provincia de Santa Fe, en 1970.             
Alumno de Juan Carlos Palacios, César López Claro y Guillermo Hoyos, entre otros.
Dibujante, retratista, intentó brindar a su creatividad múltiples vías de expresión, desempeñándose como decorador y dibujante publicitario.
Realizó numerosas muestras individuales y colectivas en importantes localidades del país.
Además se interesó por la preservación y restauración de Bienes Culturales.
Realizó diversos cursos de restauración de Madera y Pintura de Caballete.
En el año 2000 viajó a Venecia (Italia) a estudiar Restauración de Pintura Mural.
`;

const experiencesList = [
  'En los años 2006 – 2007 dirigió la restauración del Templo San Carlos Borromeo de la Ciudad de San Carlos Centro. Se intervinieron diversos sectores (muros, columnas, bóvedas interiores, muro del ábside, cornisas, altares de madera policromada y dorada a la hoja, múltiples esculturas, vitreaux, fachada del Templo, etc.)',
  'En el año 2010 trabajó y dirigió la Restauración de la pintura mural del Templo San Cayetano de la localidad de Suardi (Santa Fe).',
  'En el año 2020 realizó trabajos de pintura en un oratorio privado en la provincia de Santa Fe.',
];

const exhibitionsList = [
  'San Carlos Centro (Banco Bica)',
  'San Justo (Mutual Club Colón)',
  'Arroyito – Córdoba (Centro Municipal de Exposiciones)',
  'Pergamino – Buenos Aires (Galería Arte X Arte)',
  'Sa Pereira (Club Belgrano - Sala de Exposiciones)',
  'Santa Fe (Palacio Legislativo)',
  'Paraná – Entre Ríos (Centro Cultural J. L. Ortiz)',
  'Esperanza (Casa de la Colonia)',
  'Buenos Aires (Salón de Pasos Perdidos-Cámara de Diputados de la Nación)',
  'Buenos Aires (Centro Cultural San Martín – Sala 1)',
  'Córdoba (Museo Municipal de Bellas Artes Genaro Pérez)',
  'San Carlos Centro (Fundación Santafesina Virgen de Luján)',
  'San Carlos Centro (Museo Histórico de la Colonia)',
  'Santa Fe (Asociación de Artistas Plásticos)',
  'Rosario (Museo de Ciencias Naturales A. Gallardo)',
  'San Justo (Casa de la Cultura E. Bodrone)',
  'Santa Fe (Museo Municipal César López Claro – Museo Provincial de Bellas Artes Rosa Galisteo)',
  'Santa Fe (Defensoría del Pueblo – Artefe)',
  'Buenos Aires (Casa de la Prov. De Santa Fe)',
];

const awardsList = [
  '2º Premio- XXI Salón de Pintura, Dibujo y Grabado Club Colón de San Justo (S.Fe) - (2008)',
  '1º Premio - 74º Salón Anual Artistas Plásticos Santafesinos - (2008)',
  'Mención XII Salón Primavera A.A.P.S. (Santa Fe) - (2008)',
  '1º Premio – 1º Salón Municipalidad de Morteros - (2008)',
  '2º Premio Salón Bicentenario A.A.P.S. (Santa Fe) Mención Salón Bienal Nacional de Dibujo Quilmes Mención XIV Salón Primavera A.A.P.S.(Santa Fe) Jornada de Capacitación en Conservación y Restauración de obras en soporte Papel - (2010)',
  'Mención XII Salón Nacional de Dibujo y Grabado – Museo Artemio Alisio (Entre Ríos). - (2011)',
  '2º Premio - 77 Salón Anual Artistas Plásticos Santafesinos - (2012)',
  '4º Premio 60º Salón Anual de Ceres (Santa Fe) - (2012)',
  '3º Premio Bienal Nacional de Dibujo Quilmes (Buenos Aires) - (2013) ',
  '1º Premio 8vo. Salón de Dibujo Librería Thesis (Buenos Aires). - (2013)',
  '2° Premio XIV Salón Anual Nacional de Dibujo y Grabado de Entre Ríos - (2013)',
  'Premio XXIV Salón Club Colón San Justo (Sta. Fe). - (2014)',
  '1° Premio Salón Nacional de Dibujo Museo Artemio Alisio (Concepción del Uruguay - (Entre Ríos) - (2018)',
]
// const email = 'hola@luisdona.com';
const email = 'dona_artegrafico@yahoo.com.ar';
const phone = '+54 9 3404 50-6452';

const AboutMe = () => {
  return (
    <div id="about" className="about-area ptb--120  bg_color--1">
      <div className="about-wrapper">
        <div className="container">
          <div className="section-title">
            {/* <span className="subtitle">Our company about</span> */}
            <h2 className="title">Sobre mi</h2>
            {/* <p className="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,</p> */}
          </div>
          <div className="row row--35">
            <div className="col-lg-7">
              <div className="about-inner inner">
                <div className="row mt--30">
                  {/* details */}
                  <div>
                    {/* Start Tabs Area */}
                    <div className="tabs-area">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <Tabs>
                              <TabList className="tab-style--1">
                                <Tab>Biografía</Tab>
                                <Tab>Exposiciones</Tab>
                                <Tab>Premios</Tab>
                                <Tab>Experiencia</Tab>
                              </TabList>
                              
                              {/* Historia */}
                              <TabPanel>
                                <div className="single-tab-content">
                                  {/* <h4>Mi historia personal.</h4> */}
                                  { history }
                                </div>
                              </TabPanel>

                              {/* Exposiciones */}
                              <TabPanel>
                                <div className="single-tab-content">
                                  <div className="mt--30 mt_lg--10 mt_md--10 mt_sm--10">
                                    {/* <h4>Dónde fueron expuestas mis obras.</h4> */}
                                    <ul className="list-style--1">
                                      {exhibitionsList.map((exhibition, index) => {
                                        return <li key={ index }><FiCheck /> {exhibition}</li>;
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </TabPanel>

                              {/* Premios */}
                              <TabPanel>
                                <div className="single-tab-content">
                                  <div className="mt--30 mt_lg--10 mt_md--10 mt_sm--10">
                                    {/* <h4>Premios recibidos.</h4> */}
                                    <ul className="list-style--1">
                                      {awardsList.map((award, index) => {
                                        return <li key={ index }><FiCheck /> {award}</li>;
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </TabPanel>

                              {/* Experiencia */}
                              <TabPanel>
                                <div className="single-tab-content">
                                  <div className="mt--30 mt_lg--10 mt_md--10 mt_sm--10">
                                    {/* <h4>Experiencias en restauración.</h4> */}
                                    <ul className="list-style--1">
                                      {experiencesList.map((experience, index) => {
                                        return <li key={ index }><FiCheck /> {experience}</li>;
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </TabPanel>

                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Tabs Area */}
                  </div>
                  {/* end details */}
                </div>
              </div>
            </div>
            {/* contact */}
            <div className="col-lg-5">
              <div className="section-title text-left mb--50">
                {/* <h2 className="title contact-me__title">Hablemos.</h2> */}
                <div className="contact_methods-container">
                  <div className="contact_method-container">
                    <img className="contact_method--icon"
                    src="/assets/images/icons/whatsapp.svg" alt="Whatsapp"
                    />
                    <div className="contact_method--value">{phone}</div>
                  </div>
                  <div className="contact_method-container">
                    <img className="contact_method--icon"
                    src="/assets/images/icons/email.svg" alt="Email"
                    />
                    <div className="contact_method--value">{email}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
